<template>
  <div>
    <!-- BREADCRUMB -->
    <section
      class="breadcrumb-bg"
      style="
        background-image: url(../assets/img/background/page-title-bg-img.jpg);
      "
    >
      <div class="container">
        <div class="breadcrumb-holder">
          <div>
            <h1 class="breadcrumb-title">隱私權聲明</h1>
            <ul class="breadcrumb breadcrumb-transparent">
              <li class="breadcrumb-item">
                <a class="text-white" href="/">Home</a>
              </li>
              <li class="breadcrumb-item text-white active" aria-current="page">
                隱私權聲明
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- content -->
    <section class="py-6 py-md-9">
      <div class="container">
        <div class="py-5">
          <div class="section-title align-items-baseline">
            <h3 class="text-warning font-weight-bold pl-0 mb-3">
              【隱私權暨個人資料保護條款及資訊安全政策】
            </h3>
          </div>
          <p class="text-muted">
            關於亼富科技股份有限公司（以下簡稱本公司）所經營之「i玩錢兒童財商教育平台」（以下簡稱本站），關於您個人隱私權，請詳細閱讀以下資訊，並瞭解同意本公司如何搜集、處理及保護您的個人資料：
          </p>
          <ul class="text-muted">
            <li class="mb-2">
              基於本站提供之投資理財相關內容或理財課程，本站將會搜集您的個人資料，包括但不限於姓名、生日、年齡、行動及家用電話號碼、電子郵件、通訊及住居所地址、購買或使用之課程等個人資料，其他個人資料本站不會蒐集。
            </li>
            <li class="mb-2">
              您的個人資料將會自您成為本站會員後，持續使用直至您提出停止使用或本站停止提供服務為止。前述個人資料將會使用於本站營運之地區。
            </li>
            <li class="mb-2">
              本站為行銷、消費者保護、消費者/客戶管理與服務及其他電子商務服務、帳務管理、調查/統計與研究分析、資訊與資料庫管理、非公務機關依法定義務所進行個人資料之搜集處理及利用等目的，搜集並於前述目的範圍內處理及利用您的個人資料。
            </li>
            <li class="mb-2">
              除法律另有規定外，您得依個人資料保護法請求閱覽、查詢、補充、更正您的個人資料，或製給複製本（本公司將酌收必要費用）。
              本公司蒐集個人資料蒐集之特定目的消失或期限屆滿時，除依法令或
              本公司因執行職務、業務所必須外，您得請求刪除、停止處理或利用
              個人資料。若您選擇不提供個人資料或提供不完全時，本公司將無法
              提供完整之服務或讓您知悉相關活動資訊。
            </li>
            <li class="mb-2">
              依據個人資料保護法之規定，若您向本公司請求閱覽、查詢您的
              個人資料，或製給複製本，本公司將於15日內准駁與協助；若您請求
              補充、更正您的個人資料、刪除、停止處理或利用個人資料，本公司
              將於30日內准駁與協助。另關於拒絕行銷之要求，因應本公司需跨部
              門協助作業處理，將於收到請求後7-10日完成您的需求。
            </li>
            <li class="mb-2">
              為確保您個人資料得以在網際網路或是其他電子商務行為保持隱密性，本公司承諾會確保您個人資料的安全，您所留下的個人資料，除經您同意或政府機關、法院及其他依法令規定應提供之情形者外，絕不販賣或透露給網站以外的其他人及單位，並嚴禁內部人員私自使用這些資料。
            </li>
            <li class="mb-2">
              為提昇服務品質，提供更多貼心的服務，本公司會記錄使用者連線
              的IP位址、使用時間、瀏覽網頁等資料，也可能會使用cookies，
              Cookies是為區別使用電腦的不同，而不會辨識使用者身份，這些資料是為輔助儲存使用者特定使用偏好。
            </li>
          </ul>
        </div>
        <div class="py-5">
          <div class="section-title align-items-baseline">
            <h3 class="text-warning font-weight-bold pl-0 mb-3">
              【資訊安全政策】
            </h3>
          </div>
          <ul class="text-muted">
            <li class="mb-2">
              使用本站時，您同意本站得自動收集下列資訊：日期和時間、您所擷取之網頁、您所在之網址、您的瀏覽器種類、您對本站網頁所做行動（如：所觀看的影片與進行的練習題或資料下載等）及成功與否，並同意這些資訊可能被用來改善本站之效能。
            </li>
            <li class="mb-2">
              為確保您個人資料得以在網際網路或是其他電子商務行為保持隱密性，本公司承諾會確保您個人資料的安全，您所留下的個人資料，除經您同意或政府機關、法院及其他依法令規定應提供之情形者外，絕不販賣或透露給網站以外的其他人及單位，並嚴禁內部人員私自使用這些資料。
            </li>
            <li class="mb-2">
              將根據不同層級人員其角色及職權，開通工作所需系統管理權限，並提供相關資訊安全教育訓練，進一步讓員工瞭解資訊安全的重要性，要求其遵守資訊安全先關規定，並針對離職、停職員工，立即取消其使用各種系統的權利。
            </li>
            <li class="mb-2">
              將定期針對內部網路資訊安全設施進行備份及防毒查核動作，避免資料遺失或系統安全上的漏洞，並且定期更新防毒密碼，以確保各項安全措施。
            </li>
          </ul>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'privacy',
  mounted () {
    require('@/assets/js/base.js')
  }
}
</script>
